
.mlcam-sidebar {
    margin-bottom: 0;
}

.mlcam-sidebar .nav {
    border-bottom: 0;
}

.mlcam-sidebar .nav li {
    width: 100%;
    border-bottom: 1px solid #F0EEEA;
}


.mlcam-sidebar .nav li.active {
   background-color: #181816;
}

.mlcam-sidebar .nav li.active a {
   color: #fff;
}

.mlcam-sidebar .nav li.show li.active {
    background-color: #fff;
 }
 

.mlcam-sidebar .nav li.show .dropdown-menu a {
   color: #181816;
   
}

.mlcam-sidebar .nav li a{
    width: 100%;
    display: flex;
    align-items: center;
    color: #181816;
    text-decoration: none;
    padding: 13px 16px;
    justify-content: space-between;
    position: relative;
    font-family: 'CharlieStdMedium';
}

.mlcam-sidebar .nav li a:hover {
    text-decoration: underline;
}

.mlcam-sidebar .nav li .dropdown-menu {
    position: relative !important;
    transform: translate3d(0, 0, 0px) !important;
    margin-top: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    border-radius: 0;
    border: 0;
}

.mlcam-sidebar .nav li .dropdown-menu li {
    border-bottom: 0;   
}

.mlcam-sidebar .nav li .dropdown-menu li a {
    padding-left: 29px;
    font-family: inherit;
}

.mlcam-sidebar .nav li .dropdown-menu li a::after {
    content:''
}


.mlcam-sidebar .nav li a::after {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-left: initial;
    vertical-align: initial;
    content: "";
    border:0;
    position:absolute;
    content:"\f054";
    right: 8px;
    font-family: 'Font Awesome Pro Regular';
    font-weight: 400;
    top: 13px;
    margin: 0;
}

.mlcam-sidebar .nav li.show a::after {
    content:"\f078";
    right: 14px;
}

.mlcam-sidebar-link .text-image {
    width:100%;
    display:flex;
}

.mlcam-sidebar-link .btn::after {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-left: initial;
    vertical-align: initial;
    content:"\f023";
    border:0;
    position:absolute;
    right: 13px;
    font-family: 'Font Awesome Pro Regular';
    font-weight: 400;
    top: 10px;
    margin: 0;
}

.mlcam-sidebar-link .btn {
   color: #181816;
    position:relative;
    padding-left: 29px;
    display: flex;
    padding:10px 16px;
    width: 100%;
    align-items: center;
    font-family: 'CharlieStdMedium';
    text-decoration: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-color: transparent;
    border: 0;
}

.mlcam-sidebar-link .btn:active {
    background-color:transparent !important;
    border: 0 !important;
    color: #181816  !important;
}
       
.mlcam-sidebar-link .btn:hover {
    text-decoration: underline;
}

.mlcam-sidebar-link .button-text-wrapper, 
.mlcam-sidebar-link .button-wrapper {
    text-align: left;
}

.mlcam-sidebar-orange .nav li.active {
    background-color: $orange;
}