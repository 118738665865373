.campaign-subheading {
    hr {
        width: 55px;
        margin-left: 0;
        border: 1px solid $orange;
        margin-top: 10px;
        margin-bottom: 0;
    }

    a .fa-chevron-right-regular {
        transition: .6s all;
    }

    a:hover .fa-chevron-right-regular {
        margin-left: 25px;
        color: $deep-orange;
    }

    a:after {
        content: "";
        border: 1px solid $orange;
        width: 55px;
        height: 1px;
        display: block;
        margin-top: 10px;
      }
      
      a:hover:after {
        border-color: $deep-orange;       
      }

    h2, h3, h4, h5, a {
        font-family: $font-family-charlie-semibold;
        color: $very-dark-grey;
    }

    h3, h4, p {
        margin-bottom: 0;
    }

    .fa-chevron-right-regular {
        display: inline-block;
        margin-left: 15px;
        font-size: 14px;
        font-weight: bold;
    }

    a {
        color: $very-dark-grey;
        text-decoration: none;
    }
}

.multi-asset-product-heading {
    background-color: $black;
    color: $white;

    .text-image {
        margin-top: 15px;
        margin-bottom: 15px;
    }
}

.multi-asset-product-content {
    border: 1px solid #99948a;
    padding-top: 40px;
    padding-bottom: 20px;

    hr {
        border-color: #99948a;
        width: 180px;
    }

    .border-button {
        width: auto;

        .fa-chevron-right-regular {
            display: inline-block;
            margin-left: 15px;
        }
    }
}

.wrapper-investment-approach-video {
    background-color: $almost-white;
    margin-left: 0;
    margin-right: 0;
    padding-left: 25px;
    padding-top: 40px;
    margin-bottom: 60px;

    .investment-approach-video-content {
        padding-top: 20px;
    }
  }

@media screen and (max-width: 991px) {
    .campaign-subheading br {
        display: none;
    }

    .multi-asset-product-content {
        margin-bottom: 30px;

        br {
            display: none;
        }
    }
}

//Index Plus fund information page

.index-plus-portfolio-links a:not(:last-child) {
    margin-right: 30px;
}

.portfolio-graph img {
    width: 150px;
    height: 150px;
}

.portfolio-graph-percentage {
    p:first-child:before {
        content: "";
        width: 20px;
        height: 20px;
        background-color: $orange;
        display: inline-block;
        margin-right: 20px;
        position: relative;
        top: 2px;
    }

    p:last-child:before {
        content: "";
        width: 20px;
        height: 20px;
        background-color: $very-dark-grey;
        display: inline-block;
        margin-right: 20px;
        position: relative;
        top: 2px;
    }
}


//Campaign landing page

.campaign-navigation {
    padding-top: 20px;
    padding-bottom: 20px;
}

.campaign-masthead-wrapper {
    background-size: cover !important;
    background-position: center !important;
    min-height: 220px;

    .campaign-masthead-content {
        h1 {
            color: $orange;
        }

        p {
            color: $white;
        }
    }
}

.wrapper-content {
    color: $white;
    padding-top: 60px;
    padding-bottom: 20px;

    img {
        margin-top: 40px;
        height: 60px;
    }
}

.wrapper-portfolio-content {
    .row {
        border-bottom: 1px solid #cbcbcb;
        margin-top: 60px;
        padding-bottom: 40px;
    }

    img {
        height: 85px;
        margin-bottom: 25px;
    }

    ul {
        margin-bottom: 0;
        padding-left: 15px;

        li {
            padding-left: 15px;
            padding-bottom: 10px;
        }
    }
}

.portfolio-content-subheader {
    color: $orange;
    font-size: 18px;
    font-family: $font-family-charlie-regular;
}

.wrapper-portfolio-glance {
    background-color: $light-grey;

    .portfolio-glance-subheading {
        color: $orange;
        font-family: $font-family-charlie-semibold;
    }

    ul {
        padding-left: 15px;

        li {
            padding-left: 10px;
        }
    }

    .portfolio-glance-disclaimer {
        font-size: 14px;
        padding-top: 30px;
        border-top: 1px solid #cbcbcb;
    }

    table {
        th {
            color: $orange;
            font-weight: normal;
            font-family: $font-family-charlie-medium;
            padding-bottom: 20px;
        }

        tr {
            border-bottom: 1px solid #cbcbcb;
        }

        td {
            padding-top: 20px;

            ul {
                padding-left: 15px;

                li {
                    padding-bottom: 10px;
                    padding-left: 5px;
                }
            }

            &:first-child {
                width: 17%;
            }
        }

        img {
            height: 160px;
        }
    }
}

.portfolio-investment-countries {
    h2, h3, h4, h5, h6 {
        color: $deep-orange;
    }
}

@media screen and (min-width: 992px) {
    .campaign-masthead-content {
        background-color: $black;
        width: 447px;
        margin-top: 40px;
        margin-bottom: 40px;
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 30px;

        .text-image {
            margin-bottom: 10px;
        }
    }

    .flexible-portfolio-intro p:last-child {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width: 991px) {
    .wrapper-portfolio-content {
        br {
            display: none;
        }
    }

    .wrapper-portfolio-glance {
        br {
            display: none;
        }

        .portfolio-glance-subheading {
            margin-top: 30px;
        }

        .pie-chart {
            width: 164px;
            margin-left: auto;
            margin-right: auto;
        }

        .portfolio-glance-last {
            border-bottom: 1px solid #cbcbcb;
        }
    }

    .img-world-map {
        display: none;
    }
}