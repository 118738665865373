@include media-breakpoint-up(md) {
    .spacing-mb-lg-5 {
        margin-bottom: 5px;
    }
    
    .spacing-mb-lg-10 {
      margin-bottom: 10px;
    }
    
    .spacing-mb-lg-15 {
      margin-bottom: 15px;
    }
    
    .spacing-mb-lg-20 {
      margin-bottom: 20px;
    }
    
    .spacing-mb-lg-25 {
      margin-bottom: 25px;
    }
    
    .spacing-mb-lg-30 {
      margin-bottom: 30px;
    }

    .spacing-mb-lg-35 {
      margin-bottom: 35px;
    }
    
    .spacing-mb-lg-40 {
      margin-bottom: 40px;
    }
    
    .spacing-mb-lg-50 {
      margin-bottom: 50px;
    }
    
    .spacing-mb-lg-60 {
      margin-bottom: 60px;
    }

    .spacing-pt-lg-60 {
      padding-top: 60px;
    }

    .spacing-pt-lg-30 {
      padding-top: 30px;
    }
}

@include media-breakpoint-down(md) {
    .spacing-mb-5 {
        margin-bottom: 5px;
    }

    .spacing-mb-10 {
        margin-bottom: 10px;
    }

    .spacing-mb-15 {
        margin-bottom: 15px;
    }

    .spacing-mb-20 {
        margin-bottom: 20px;
    }

    .spacing-mb-30 {
        margin-bottom: 30px;
    }

    .spacing-mb-40 {
        margin-bottom: 40px;
    }

    .spacing-mb-50 {
        margin-bottom: 50px;
    }

    .spacing-mb-60 {
        margin-bottom: 60px;
    }

    .spacing-pt-30 {
      padding-top: 30px;
    }
}

@media screen and (min-width: 992px) {
  @include margin-top-loop('.spacing-mt-lg-', 'margin-top');

  @include margin-bottom-loop('.spacing-mb-lg-', 'margin-bottom');

  @include padding-top-loop('.spacing-pt-lg-', 'padding-top');

  @include padding-bottom-loop('.spacing-pb-lg-', 'padding-bottom');
}

@media screen and (max-width: 991px) {
  @include margin-top-loop('.spacing-mt-', 'margin-top');

  @include margin-bottom-loop('.spacing-mb-', 'margin-bottom');

  @include padding-top-loop('.spacing-pt-', 'padding-top');

  @include padding-bottom-loop('.spacing-pb-', 'padding-bottom');
}