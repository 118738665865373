  @font-face {
    font-family:"CharlieStdMedium";
    src:
            url("/etc.clientlibs/mlc-designs/clientlibs/bootstrap/bootstrap-managed-designs/bootstrap-mlcsecure/clientlibs/resources/fonts/charlie/Charlie-Std-Medium.eot?iefix") format("eot"),
            url("/etc.clientlibs/mlc-designs/clientlibs/bootstrap/bootstrap-managed-designs/bootstrap-mlcsecure/clientlibs/resources/fonts/charlie/Charlie-Std-Medium.woff") format("woff"),
            url("/etc.clientlibs/mlc-designs/clientlibs/bootstrap/bootstrap-managed-designs/bootstrap-mlcsecure/clientlibs/resources/fonts/charlie/Charlie-Std-Medium.ttf") format("truetype"),
            url("/etc.clientlibs/mlc-designs/clientlibs/bootstrap/bootstrap-managed-designs/bootstrap-mlcsecure/clientlibs/resources/fonts/charlie/Charlie-Std-Medium.svg") format("svg");
  }
  
  @font-face {
    font-family:"CharlieStdRegular";
    src:
            url("/etc.clientlibs/mlc-designs/clientlibs/bootstrap/bootstrap-managed-designs/bootstrap-mlcsecure/clientlibs/resources/fonts/charlie/Charlie-Std-Regular.eot?iefix") format("eot"),
            url("/etc.clientlibs/mlc-designs/clientlibs/bootstrap/bootstrap-managed-designs/bootstrap-mlcsecure/clientlibs/resources/fonts/charlie/Charlie-Std-Regular.woff") format("woff"),
            url("/etc.clientlibs/mlc-designs/clientlibs/bootstrap/bootstrap-managed-designs/bootstrap-mlcsecure/clientlibs/resources/fonts/charlie/Charlie-Std-Regular.ttf") format("truetype"),
            url("/etc.clientlibs/mlc-designs/clientlibs/bootstrap/bootstrap-managed-designs/bootstrap-mlcsecure/clientlibs/resources/fonts/charlie/Charlie-Std-Regular.svg") format("svg");
  }

  @font-face {
    font-family:"CharlieStd-SemiBold";
    src:
            url("/etc.clientlibs/mlc-designs/clientlibs/bootstrap/bootstrap-managed-designs/bootstrap-mlcsecure/clientlibs/resources/fonts/charlie/Charlie-Std-SemiBold.eot?iefix") format("eot"),
            url("/etc.clientlibs/mlc-designs/clientlibs/bootstrap/bootstrap-managed-designs/bootstrap-mlcsecure/clientlibs/resources/fonts/charlie/Charlie-Std-SemiBold.woff") format("woff"),
            url("/etc.clientlibs/mlc-designs/clientlibs/bootstrap/bootstrap-managed-designs/bootstrap-mlcsecure/clientlibs/resources/fonts/charlie/Charlie-Std-SemiBold.ttf") format("truetype"),
            url("/etc.clientlibs/mlc-designs/clientlibs/bootstrap/bootstrap-managed-designs/bootstrap-mlcsecure/clientlibs/resources/fonts/charlie/Charlie-Std-SemiBold.svg") format("svg");
  }
