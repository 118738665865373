.accordion {
    .card-header {
        background-color: $bg-grey !important;
        margin-bottom: $paragraph-margin-bottom;
        a {
            display: block;
            position: relative;
            padding: 15px 20px;
            font-family: $font-family-base;
            color: $white;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
            &::after {
                content: "\f068";
                font-family: $font-family-font-awesome;
                position: absolute;
                padding: inherit;
                top: 0;
                right: 0;
            }
            &.collapsed::after {
                content: "\f067";
            }
        }
    }
}

.accordion-inline {
    .card {
        background-color: transparent;
    }
    .card-header {
        background-color: transparent !important;
        a {
            padding: 0;
            color: $link-color;
            &::after {
                content: "\f077";
                right: auto;
                margin-left: 10px;
            }
            &.collapsed::after {
                content: "\f078";
            }
        }
    }
    .media-left {
        display: none;
    }
}

//plain accordion

.accordion-link {
        .card-header {
            background-color: transparent !important;
            margin-bottom: 0;
        }
        
        a.accordion-toggle {
            color: #086b82;
            position: relative;
            display: inline-flex;
            align-items: center;
            padding-left: 25px;
        }
        
         a.accordion-toggle:before {
            position: absolute;
            left: 0;
            content:"\f05a";
            font-family: 'Font Awesome Pro Regular';
            width: 20px;
        }
        
        a.accordion-toggle.collapsed:after {
            content:"\f078";
        }
        
        a.accordion-toggle:after {
            position: absolute;
            content:"\f077";
            right: 0;
            font-family: 'Font Awesome Pro Regular';
            right: -20px;
        }
    
} 
