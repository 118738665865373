html {
    overflow-x: hidden;
}

h1, h2, h3, h4, h5 {
    font-family: $font-heading;
    font-size: $font-size-base;
}
h1, h2, h3, h4, h5,
.font-size-huge, .font-size-big, .font-size-regular, .font-size-little, .font-size-tiny {
    margin-top: $font-heading-margin;
    margin-bottom: $font-heading-margin;
    line-height: 1.25;
}
a {
    text-decoration-skip-ink: none;
}

.font-size-huge {
    font-size: $font-size-huge;
}
.font-size-big {
    font-size: $font-size-big;
}
.font-size-regular {
    font-size: $font-size-regular;
}
.font-size-little {
    font-size: $font-size-little;
}
.font-size-tiny {
    font-size: $font-size-tiny;
}

.font-size-small {
    font-size: $font-size-small;
}
.font-size-tiniest {
    font-size: $font-size-tiniest;
}

.helvetica-medium {
    font-weight: 500;
}

.helvetica-bold {
    font-family: $font-family-helvetica-bold;
}

.chaliestd-semibold {
    font-family: $font-family-charlie-semibold;

    * {
        font-family: $font-family-charlie-semibold;
    }
}

.charliestd-regular {
    font-family: $font-family-charlie-regular;

    * {
        font-family: $font-family-charlie-regular;
    }
}

small, .small, .font-tiniest-grey {
    color: $grey;
    font-size: $font-size-tiniest;
}

.font-tiniest-orange {
    color: $orange;
    font-size: $font-size-tiniest;
}

.primary-button {
    display: inline-block;
    background-color: $orange;
    color: $white;
    padding: 0 20px;
    margin: 5px 0;
    line-height: 44px;
    font-family: $font-family-charlie-regular;
    font-size: $font-size-tiny;
    text-align: center;
    &:hover, &:focus, &:active {
        background-color: $deep-orange;
        outline: none;
        text-decoration: underline;
    }
}
.border-button {
    @extend .primary-button;
    background-color: transparent;
    color: $orange;
    border: 3px solid $orange;
    &:hover, &:focus, &:active {
        background-color: transparent;
    }
}

.border-button-white {
    @extend .primary-button;
    background-color: transparent;
    color: $white;
    border: 3px solid $white;
    &:hover, &:focus, &:active {
        background-color: transparent;
    }
}

.plain-button {
    @extend .border-button;
    border: 0;
}

.text-orange, .text-orange * {
    color: $orange !important;
}
.text-white, .text-white * {
    color: $white !important;
}
.text-very-dark-grey, .text-very-dark-grey * {
    color: $very-dark-grey !important;
}
.bg-grey {
    background-color: $bg-grey !important;
}
.bg-light-grey {
    background-color: $light-grey !important;
}
.bg-almost-white {
    background-color: $almost-white !important;
}

.bg-black {
    background-color: $black;
}

.fa-fw {
    width: 2em;
}

.padding-left-15 {
    padding-left: 15px;
}

.padding-right-15 {
    padding-right: 15px;
}

.content-header-border {
    .text-image {
        margin-bottom: 0;
        border-left: 1px solid $orange;
        height: auto;
        padding-left: 15px;
    }
}

table.table {
    th, td {
        padding: $table-cell-padding-sm;
    }
    th {
        padding: $table-cell-padding-md;
        background-color: $table-head-bg;
        border-bottom: 2px solid $table-head-border-color;
        font-weight: normal;
        font-size: $font-size-sm;
    }
}
table.table-fund-facts {
    tbody tr:nth-of-type(even) {
        background-color: $table-accent-bg;
    }
    th, td {
        padding: $table-cell-padding;
        border: 0;
    }
    th {
        background-color: inherit;
        color: $body-color;
        font-family: $font-heading;
        font-size: $font-size-tiny;
    }
}

table.table-fund-facts-single {
    tbody tr:nth-of-type(even) {
        background-color: $table-accent-bg;
    }
    th, td {
        padding: $table-cell-padding;
        border: 0;
    }
    th {
        background-color: inherit;
        color: $body-color;
        font-family: $font-heading;
        font-size: $font-size-tiny;
    }
    th, td:nth-child(even) {
        background-color: $table-accent-bg;
    }
}

/* Section spacing and division, applied to row */
.section-wrapper .text-image:last-child {
    margin-bottom: 0;
    p:last-child, div:last-child {
        margin-bottom: 60px;
    }
}
.section-wrapper {
    border-bottom: 1px solid #F0EEEA;
    margin-bottom: 60px;
}

.block-wrapper {
    border-bottom: 1px solid #F0EEEA;
    padding-bottom: 60px;
    margin-bottom: 60px;
    .bootstrap-row:last-child .file-blocks p:last-child {
        margin-bottom: 0;
    }
}

.text-image {
    margin-bottom: $paragraph-margin-bottom;
}
.media-list, .media>.pull-left {
    padding: 0 !important;
}
.v-player-container {
    .jwplayer.jw-reset.jw-state-idle {
        @extend %gradient-overlay-video;
    }
}
.flex-video {
    padding-top: 0 !important;
    margin-bottom: $paragraph-margin-bottom !important;
}

.page-content--intro {
    .text-image {
        margin-bottom: 0;
    }

    .page-content--intro-heading {
        margin-bottom: 30px; 
        @include media-breakpoint-down(md) {
            margin-bottom: 10px; 
        }

    }

    .page-content--intro-description {
        p:last-child {
            margin-bottom: 0;
        }
    }
}

.page-content {
	padding-top: 60px;
    padding-bottom: 60px;

    .fa-file-pdf,
    .fa-file-powerpoint,
    .fa-file-word,
    .fa-file-excel {
        display: inline-block;
        vertical-align: top;
        margin-top: 4px;
    }
    .link-icon-separator {
        display: inline-block;
        width: calc(100% - 40px);
    }

    @include media-breakpoint-down(md) {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    
    &.page-content-bg--grey {
        background-color: $almost-white;   
    }

    &.page-content-bg--black {
        background-color: $black;   
    }
}
.plain-link {
    a {
        text-decoration: none !important;
    }
}
.file-blocks {
    p {
        margin-bottom: 20px;
    }
    h3 {
        margin-bottom: 15px;
    }
}

.block-heading {
    .text-image {
        margin-bottom: 60px;
        @include media-breakpoint-down(md) {
            margin-bottom: 30px;
        }
    }
}

.primary-button {
    .fa-sign-in,
    .fa-play {
        margin-left: 15px;
    }
}
.block-space-mb {
    margin-bottom: 60px;
    p:last-child, .text-image:last-child {
        margin-bottom: 0;
    }
}
.item-space-mb {
    margin-bottom: 30px;
}
.block-item-spacing-mb {
    p {
        margin-bottom: 10px !important;
    }
}
.contacts-form {
    .PageWrapper {
        margin-top: 0;
        margin-bottom: 0;
    }
}
//Generic styles

.hr {
    margin-top: 40px;
    margin-bottom: 55px;
    border-top: 1px solid $light-grey;
}

.sr-only {
	border: 0 !important;
	clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
	-webkit-clip-path: inset(50%) !important;
		clip-path: inset(50%) !important;  /* 2 */
	height: 1px !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
	white-space: nowrap !important;            /* 3 */
}

.how-to-invest-subheader {
    margin-top: 55px;
}

.helvetica-medium-header {
    color: $orange;
    font-size: $font-size-tiny;
    font-weight: 500;
    
    .text-image {
        margin-bottom: 0;
    }
}

.content-wrapper-how-to-invest {
    .text-image {
        margin-bottom: 15px;

        p {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
}

// Added styles for the accordion table (Product Overview and documents page)

.accordion-table {
    .card-body {
        .list-item-text {
            width: 100%;
        }

        table {
            width: 100%;
            margin-top: -20px;

            tr {
                border-bottom: 1px solid $light-grey;

                &:last-child {
                    border-bottom: 0;
                }
            }

            td {
                vertical-align: top;
                padding-top: 40px;
                padding-bottom: 20px;

                p {
                    width: 90%;
                    min-height: 50px;
                }

                &:first-child {
                    width: 25%;

                    p {
                        margin-top: 10px;
                    }

                    h3 {
                        width: 95%;
                    }
                }

                &:last-child {
                    position: relative;
                }

                a {
                    span.fa-file-pdf {
                        vertical-align: top;
                        width: 10%;
                        display: inline-block;
                        margin-top: 5px;
                    }

                    span.link-icon-separator {
                        width: 90%;
                        display: inline-block;
                        text-decoration: underline;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }   
}

//News and media

.wrapper-news-and-media {
    margin-top: 55px;
    margin-bottom: 20px;

    .wrapper-news-and-media-content {
        margin-top: 60px;
    }

    .news-and-media-article-title {
        margin-top: 23%;
    }
}

//Unit prices 

.mlc-unitprices-search {
    margin-top: 30px;

    label {
        font-weight: bold;
    }

    fieldset {
        float: left;
    }

    fieldset + p {
        clear: both;
    }

    .unit-price-fund-name {
        margin-right: 30px;

        label {
            position: relative;

            &::after {
                font-family: 'Font Awesome Pro Regular';
                content: "\f078";
                pointer-events: none;
                position: absolute;
                top: 38px;
                right: 10px;
            }
        }

        select {
            padding: 0 10px;
            height: 44px;
            margin-top: 5px;
            width: 450px;
            border: 1px solid $light-grey;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border-radius: 0;
            background-color: $white;
            &:focus::-ms-value {
                height: 25px;
            }
        }

        select::-ms-expand {
            display: none;
        }
    }

    .unit-price-start-date {
        margin-right: 30px;
        width: 225px;
    }

    .unit-price-to-date {
        width: 225px;
    }

    .hasDatepicker {
        background-image: url('/content/dam/mlcam/images/icons/calendar.png');
        background-repeat: no-repeat;
        background-position: 96.5% center;
        height: 44px;
        padding-left: 10px;
        padding-right: 10px;
        border: 1px solid $light-grey;
        margin-top: 5px;
    }

    #startDate, #endDate {
        &::-ms-clear {
            display: none;
        }
    }

    #unitprice-submit {
        @extend .primary-button;
        margin-top: 30px;
        border-radius: 0;
        margin-bottom: 35px;
    }

    .unit-price-daily {
        .UnitPriceDailyText {
            p {
                width: 52%;
            }
        }
    }

    .unit-price-monthly {

        .UnitPriceMonthlyText {
            p {
                width: 52%;
            }
        }
    }

    .result-container-daily,
    .result-container-monthly {
        margin-top: 30px;

        a::before {
            font-family: 'Font Awesome Pro Regular';
            content: "\f15b";
            display: inline-block;
            margin-right: 10px;
        }

        p {
            margin-top: 20px;
        }

        table {
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }
}

.unit-price-accordion-table {
    @include fund-documents-table;
}

//Footer link styles

.footer-links ul {
    padding-left: 0;

    li {
        float: left;
        padding-right: 20px;
        list-style-type: none;
    }
}

//Contact us map styles

.ausmap {
    background-image: url('/content/dam/mlcam/images/map/mlcAusMap.png');
    background-repeat:no-repeat;
    height: 410px;
    margin: 20px auto 0 auto !important;
    position: relative;
    width: 410px;
}
  
a span.wa {
    height: 250px;
    left: 0px;
    position: absolute;
    text-indent:-9999px;
    top: 35px;
    width: 160px;
}
  
a span.wa:hover {
    background-image: url('/content/dam/mlcam/images/map/wa_hover.png');
    background-repeat: no-repeat;
}
  
a span.qld {
    height: 205px;
    right: 2px;
    position: absolute;
    text-indent:-9999px;
    top: 0px;
    width: 155px;
}
  
a span.qld:hover {
    background-image: url('/content/dam/mlcam/images/map/qld_hover.png');
    background-repeat: no-repeat;
}
  
a span.nsw {
    height: 105px;
    right: -2px;
    position: absolute;
    top: 196px;
    width: 130px;
    text-indent:-9999px;
}
  
a span.nsw:hover {
    background-image: url('/content/dam/mlcam/images/map/nsw_hover.png');
    background-repeat: no-repeat;
}
  
a span.vic {
    height: 60px;
    right: 0px;
    position: absolute;
    text-indent:-9999px;
    top: 264px;
    width: 128px;
}
  
a span.vic:hover {
    background-image: url('/content/dam/mlcam/images/map/vic_hover.png');
    background-repeat: no-repeat;
}

a span.sa {
    height: 137px;
    left: 161px;
    position: absolute;
    text-indent: -9999px;
    width: 121px;
    bottom: 100px;
  }
  
  a span.sa:hover {
      background-image: url('/content/dam/mlcam/images/map/sa_hover.png');
      background-repeat: no-repeat;
  }

.disclaimer {
    p {
        line-height: 21px;
        margin-bottom: 5px;
    }
}
.err-page-content {
    padding-top: 200px;
    padding-bottom: 200px;
}
@include media-breakpoint-up(lg) {
    .navbar-toggler {
        display: none;
    }
}

@include media-breakpoint-down(md) {
    html,
    body {
        width: 100%;
        overflow-x: hidden;
    }

    .hr {
        margin-top: 10px;
        margin-bottom: 25px;
    }

    .text-centered-mobile {
        display: block;
        text-align: center;
    }
    
    .font-size-huge {
        font-size: $font-size-big;
    }
    .font-size-big {
        font-size: $font-size-little;
    }
    .font-size-regular {
        font-size: $font-size-regular;
    }

    p, .text-image {
        margin-bottom: $paragraph-margin-bottom * 0.5;
    }

    .primary-button {
        width: 100%;
    }

    .mlcam-sidebar {
        margin-bottom: 25px;
    }

    .wrapper.header-wrapper-2 {
        background-position: 70% 100% !important;
    }

    table.table-fund-facts {
        th, td {
            display: block;
            width: 100%;
        }
        th {
            padding-bottom: $table-cell-padding * 0.25;
        }
        td {
            padding-top: 0;
        }
    }
    .page-content {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .block-space-mb {
        margin-bottom: 30px;
    }
    .item-space-mb {
        margin-bottom: 20px;
    }

    .accordion-table {
        .card-body {
            table {
                td {
                    display: block;
                    padding-top: 0;
                    padding-bottom: 0;

                    p {
                        width: 100%;
                        min-height: auto;
                    }
                    
                    &:first-child {
                        width: 100%;
                        padding-top: 20px;
                        padding-bottom: 10px;

                        h3 {
                            width: 100%;
                        }
                    }

                    &:last-child {
                        margin-bottom: 30px;
                        
                        p:last-child {
                            bottom: 0;
                            position: relative;
                        }
                    }

                    br {
                        display: none;
                    }
                }
            }
        }

        .view-resources-link {
            table td:last-child p:last-child {
                margin-left: 10px;
                margin-top: -20px;
            }
        }
    }

    .wrapper-daily-unit-prices,
    .wrapper-table {
        width: 100%;
        max-width: 100%;
        overflow-x: auto;
    }

    .wrapper-table {
        margin-bottom: 30px;
    }

    .card-wrapper-how-to-invest {
        .card-description {
            padding-left: 5px;
        }
    }

    .how-to-invest-subheader {
        margin-top: 25px;
    }

    .wrapper-news-and-media {
        margin-top: 30px;

        .wrapper-news-and-media-content {
            margin-top: 30px;
        }

        .news-and-media-article-title {
            margin-top: 0;
        }

        .news-and-media-article-image {
            margin-top: 20px;
        }
    }

    //Unit prices styles

    .mlc-unitprices-search {
        margin-top: 15px;

        .unit-price-fund-name {
            margin-right: 0;
            width: 100%;

            select {
                width: 100%;
                padding: 0 35px 0 10px;
            }
        }

        .unit-price-start-date {
            width: 155px;
            margin-right: 15px;
            margin-top: 20px;
        }

        .unit-price-to-date {
            width: 155px;
            margin-right: 0;
            margin-top: 20px;
        }

        .hasDatepicker {
            width: 155px;
        }

        #unitprice-submit {
            margin-top: 22px;
            margin-bottom: 20px;
        }

        .unit-price-daily {
            margin-bottom: 0;

            .UnitPriceDailyText {
                h2 {
                    margin-bottom: 10px;
                }
    
                p {
                    width: 100%;
                }
            }
        }

        .result-container-daily,
        .result-container-monthly {
            margin-top: 20px;

            table {
                margin-top: 20px;
            }
        }

        .unit-price-monthly {
            margin-top: 25px;

            .UnitPriceMonthlyText {
                p {
                    width:100%;
                }
            }
        }
    }

    .footer-links ul {
        li {
            width: 100%;
            padding-bottom: 10px;
        }
    }
    .err-page-content {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

//Managed account styles

.no-margin {
    .text-image,
    p {
        margin-bottom: 0;
    }
}