
.mlcam-navigation {
    background-color: #161818
}

.mlcam-navigation .navbar {
    padding: 10px 0
}

.mlcam-navigation  .navbar-brand {
    padding: 0;
    margin: 0;
    height: 56px;
}

.mlcam-navigation #secondaryNav {
    display: none;
}

.mlcam-navigation .nav-item.show .dropdown-toggle::after {
    content:"\f077";
}

.mlcam-navigation .nav-item .dropdown-toggle::after {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-left: initial;
    vertical-align: initial;
    content: "";
    border:0;
    position:absolute;
    content:"\f078";
    right: 0;
    font-family: 'Font Awesome Pro Regular';
    font-weight: 400;
    top: 7px;
    margin: 0;
}

.mlcam-navigation  .nav-item.last .nav-link:before{
    position:absolute;
    content:"\f406";
    left: -26px;
    top: 6px;
    bottom: 0;
    font-family: 'Font Awesome Pro Regular';
    font-weight: 400;
}

.mlcam-navigation .navbar-nav .dropdown-menu .dropdown-item {
    color: white;
    padding: .5rem 1.5rem;
    font-family: 'CharlieStdMedium';
    white-space: initial;
    text-decoration: none;
}

.mlcam-navigation .navbar-nav .dropdown-menu .dropdown-item:hover {
    background-color:#161818;
    text-decoration: underline;
}


.mlcam-navigation .navbar-nav .dropdown-menu {
    background-color:#161818;

}

.mlcam-navigation .navbar-nav .dropdown-menu ul {
    list-style: none;
    padding-left: 0;
}

.mlcam-navigation .navbar-nav .dropdown-menu ul li {
    list-style: none;
    padding-left: 20px;
    padding-right :20px;
    background: #161818;
}

// Sticky nav styles created 23/07/21 - Tapan Vashi //

.wrapper-sticky-nav {
    background-color: #424448;
    box-shadow: 5px 7px 8px rgba(22,22,24,0.35);
}

#mlcStickyNav {
    padding: 0;

    .nav-item {
        text-align: center;
        position: relative;
        border-bottom: 5px solid transparent;

        .nav-link {
            width: 364px;
            height: 55px;
            padding: 15px 0 0 0;
            padding-top: 15px;
            padding-bottom: 0;
            padding-left: 0;
            padding-right: 0;
            color: $white;
            text-decoration: none;
        }

        &.active,
        &:hover,
        &:focus {
            border-bottom: 5px solid $orange;
        }

        &.chevron-down:before {
            content:"\f13a";
            font-family: "Font Awesome Pro Solid";
            font-size: 26px;
            position: absolute;
            top: 40px;
            color: #303234;
            cursor: pointer;
        }

        &.chevron-up:before {
            content:"\f139";
            font-family: "Font Awesome Pro Solid";
            font-size: 26px;
            position: absolute;
            top: 40px;
            color: #303234;
            cursor: pointer;
        }

        &:hover.chevron-down:before,
        &:hover.chevron-up:before {
            display: none;
        }
    }
}

#mlcStickyNav.intermede-sticky-nav-hover {
    .nav-item {
        &:hover,
        &:focus,
        &.active {
            border-bottom: 5px solid #916c40;
        }
    }
}

@media (min-width: 992px) {
    .mlcam-navigation  .navbar-nav {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 20px;
    }

    .mlcam-navigation  .nav-item {
        position: relative
    }

    .mlcam-navigation .nav-item .nav-link {
        font-family: "CharlieStdMedium";
        color: white;
        text-decoration: none;
        position: relative;
        padding-right: 26px;
    }

    .mlcam-navigation .nav-item .nav-link:hover {
        color: white;
        text-decoration: underline;
    }

    .mlcam-navigation .nav-item.first {
        text-align: left
    }

    .mlcam-navigation .nav-item:nth-last-child(3) {
        border-right: 1px solid rgba(255, 255, 255, 0.5);
        padding-right: 15px;
    }

    .mlcam-navigation .nav-item.last {
        text-align: right;
        padding-left: 30px;
    }

    .mlcam-navigation .brand-info {
        margin-top: 25px;
        margin-bottom: 15px;
        width: 100%;
    }

    .mlcam-navigation .navbar-nav .dropdown-menu {
        position: absolute;
        top: 45px;
        padding-bottom: 10px;
        padding-top: 10px;
        border-radius: 0;
        left: -30px;
        background: #161818;
        right: -30px;
    }

    .mlcam-navigation .navbar-nav .dropdown-menu .dropdown-item {
        color: white;
        padding: 10px 0 10px 30px;
        font-family: 'CharlieStdMedium';
        white-space: normal;
    }

    .mlcam-navigation .navbar-nav .dropdown-menu .dropdown-item:focus,
    .mlcam-navigation .navbar-nav .dropdown-menu .dropdown-item:hover {
        background-color:#161818
    }

    .mlcam-navigation .navbar-nav .dropdown-menu {
        background-color:#161818;
        width: 250px;
    }

    .mlcam-navigation .navbar-nav .dropdown-menu ul {
        list-style: none;
        padding-left: 0;
    }

    .mlcam-navigation .navbar-nav .dropdown-menu ul li {
        list-style: none;
        padding-left: 0;
        background: #161818;
    }

    #mlcStickyNav {
        .navbar-brand {
            display: none;
        }
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .wrapper-sticky-nav {
        #mlcStickyNav {
            margin-left: -15px;
            margin-right: -15px;

            .nav-link {
                width: 320px;
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    .mlcam-navigation .nav-item:nth-last-child(3) {
        padding-right: 40px;
    } 
}

@media (max-width: 991px) {
    .mlcam-navigation  .navbar-toggler {
        background-color: transparent;
        margin-left: auto;
    }

    .mlcam-navigation .navbar {
        padding: 11px;
    }
    
    .mlcam-navigation  .navbar-nav .nav-item .nav-link {
        color: white;
    }


    .mlcam-navigation  .navbar-nav .nav-item.last .nav-link {
        padding-left: 30px;
    }

    .mlcam-navigation  .navbar-nav .nav-item{
        border-bottom: 1px solid #473F3A;
        font-family: 'CharlieStdMedium';
    }

    .mlcam-navigation  .navbar-nav .nav-item .dropdown.show {
        border-bottom: 0;
    }

    .mlcam-navigation .nav-item:nth-last-child(3) {
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        padding-left: 0;
    }

    .mlcam-navigation  .navbar-nav .nav-item.last {
        border-bottom: 0;
        border-top: 0;
    }
    .mlcam-navigation  .navbar-nav .nav-item .nav-link{
        text-decoration: none;
        font-size: 18px;
        padding: 0;
        padding: 15px 0;
    }
    .mlcam-navigation  .navbar-nav .nav-item.last .nav-link:before{
        left: 0;
        top: 15px;
    }

    .mlcam-navigation  .navbar-nav .dropdown-menu {
        border-top: 1px solid #473F3A;
    }
    .mlcam-navigation .navbar-nav .dropdown-menu .dropdown-item {
        padding: 0;
        font-family: 'CharlieStdMedium';
        text-decoration:none;
        padding: 5px 0;
    }
    .mlcam-navigation .navbar-nav .dropdown-menu .dropdown-item:focus {
        background-color:#161818
    }
    .mlcam-navigation .oncanvas-header {
        display:flex;
        justify-content: space-between;
        margin-bottom: 10px;
        margin-top: 10px;
        align-items: center;
    }
    .mlcam-navigation .navbar-nav .dropdown-menu {
        background-color:#161818;
        border-radius: 0;
        border-left: 0;
        border-right: 0;
        margin: 0;
    }

    .mlcam-navigation  .navbar-collapse {
        background-color: #161818;
    }
    .mlcam-navigation .navbar-toggler {
        width: 16px;
        height: 16px;
        border-top: 2px solid white;
        border-bottom: 2px solid white;
        border-radius: 0;
        border-left: none;
        border-right: none;
        padding: 0;
    }

    .mlcam-navigation .oncanvas-header .navbar-toggler {
        width: 16px;
        height: 16px;
    }

    .mlcam-navigation .oncanvas-header .navbar-toggler .navbar-toggler-icon:before,
    .mlcam-navigation .oncanvas-header .navbar-toggler .navbar-toggler-icon:after {
        position: absolute;
        left: 8px;
        content: ' ';
        height: 20px;
        width: 2px;
        background-color: white;
    }
    .mlcam-navigation .oncanvas-header .navbar-toggler .navbar-toggler-icon:before {
        transform: rotate(45deg);
    }
    .mlcam-navigation .oncanvas-header .navbar-toggler .navbar-toggler-icon:after {
        transform: rotate(-45deg);
    }

   .mlcam-navigation  .oncanvas-header .navbar-toggler {
        border-top: 0;
        border-bottom: 0;
        position:relative  
    }

    .mlcam-navigation  .oncanvas-header .navbar-toggler .navbar-toggler-icon {
        background-color: transparent;
        border-bottom: 0;
        height: 16px;
    }
    .mlcam-navigation  .navbar-toggler .navbar-toggler-icon {
        border-bottom: 2px solid white;
        height: 2px;
        background-color: white;
        display: block;
        margin-left: 0px;
        width: 16px;
    }

    .mlcam-navigation  .navbar-brand {
        height: 41px
    }

    .mlcam-navigation .nav-item .dropdown-toggle::after {
        top: 17px;
    }

    // Sticky nav styles added 23/07/2021 - Tapan Vashi //

    .wrapper-sticky-nav {
        .container {
            padding: 0;
            max-width: 100%;
        }
    }

    .sticky-nav-margin-top {
        margin-top: 100px;
    }

    #mlcStickyNav {
        position: relative;

        .navbar-brand {
            height: 55px;
            text-align: center;
            padding-top: 12px;
            margin-right: 0;
            width: 100%;
            color: $white;
        }

        .navbar-toggler {
            background-color: transparent;
            position: absolute;
            top: 15px;
            right: 0;
            margin-right: 15px;

            .navbar-toggler-icon {
                display: none;
            }

            &:before {
                content:"\f078";
                font-family: "Font Awesome Pro Light";
                font-size: 20px;
                color: $white; 
            }

            &[aria-expanded="true"]:before {
                content:"\f077";
                font-family: "Font Awesome Pro Light";
                font-size: 20px;
                color: $white; 
            }
        }

        .navbar-nav {
            margin-left: -15px;
            margin-right: -15px;
            background-color: #303234;
        }

        .nav-item {
            border-top: 1px solid #979797;

            &.chevron-down:before,
            &.chevron-up:before {
                display: none;
            }

            .nav-link {
                width: 100%;
            }
        }
    }
}
