.model-portfolio-table {
    @include table-mobile;

    .table {
        td {
            &:nth-child(3) {
                img {
                    max-width: 255px;
                }
            }
        }
    }
}