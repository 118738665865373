%gradient-overlay {
    position: relative;
    &::before {
        content: "";
        background-image: linear-gradient(90deg, rgba($black, 0.8) 35%, transparent 70%);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}
%gradient-overlay-light {
    position: relative;
    &::before {
        content: "";
        background-image: linear-gradient(90deg, rgba($white, 0.8) 35%, transparent 70%);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}

%gradient-overlay-video {
    position: relative;
    &::after {
        content: "";
        background-image: linear-gradient(180deg, transparent 0%, rgba($black, 0.7) 100%);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        pointer-events: none;
    }
}

@mixin fund-documents-table {
    .card-body {
        .list-item-text {
            width: 100%;
        }
    
        table {
            width: 100%;
            margin-top: -20px;

            tr {
                border-bottom: 1px solid $light-grey;

                &:last-child {
                    border-bottom: 0;
                }
            }

            td {
                vertical-align: top;
                padding-top: 40px;
                padding-bottom: 20px;

                p {
                    width: 90%;
                    min-height: 50px;
                }

                &:first-child {
                    width: 25%;

                    p {
                        margin-top: 10px;
                    }

                    h3 {
                        width: 95%;
                    }
                }

                a {
                    span.fa-file-pdf {
                        vertical-align: top;
                        width: 10%;
                        display: inline-block;
                        margin-top: 5px;
                    }

                    span.link-icon-separator {
                        width: 90%;
                        display: inline-block;
                        text-decoration: underline;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
   }

   @include media-breakpoint-down(md) {
       .card-body {
            table {
                td {
                    display: block;
                    padding-top: 0;
                    padding-bottom: 0;

                    p {
                        width: 100%;
                        min-height: auto;
                    }
                    
                    &:first-child {
                        width: 100%;
                        padding-top: 20px;
                        padding-bottom: 10px;

                        h3 {
                            width: 100%;
                        }
                    }
                }
            }
        }
   }
}

$max: 70;
$offset: 5;
$unit: 'px';

@mixin margin-top-loop($className, $styleName) {
    $i: 0;
    @while $i <= $max {
      #{$className + $i} { 
        #{$styleName}: #{$i + $unit};
      }
      $i: $i + $offset;
    }
}

@mixin margin-bottom-loop($className, $styleName) {
    $i: 0;
    @while $i <= $max {
      #{$className + $i} { 
        #{$styleName}: #{$i + $unit};
      }
      $i: $i + $offset;
    }
}

@mixin padding-top-loop($className, $styleName) {
    $i: 0;
    @while $i <= $max {
      #{$className + $i} { 
        #{$styleName}: #{$i + $unit};
      }
      $i: $i + $offset;
    }
}

@mixin padding-bottom-loop($className, $styleName) {
    $i: 0;
    @while $i <= $max {
      #{$className + $i} { 
        #{$styleName}: #{$i + $unit};
      }
      $i: $i + $offset;
    }
}

@mixin tabs {
    .nav-tabs {
        border: 0;

        .nav-link {
            border: 0;
            border-bottom: 1px solid $medium-grey;
            text-decoration: none;
            min-width: 335px;
            text-align: center;
            color: $very-dark-grey;

            &:hover,
            &:focus {
                color: $orange;
                border-bottom-color: $orange;
            }

            &.active {
                color: $orange;
                border-bottom: 3px solid $orange;
            }
        }
    }

    @include media-breakpoint-down(md) {
        .nav-tabs {
            .nav-link {
                min-width: 1px;
                width: 44vw;
            }
        }
    }
}

@mixin hero-banner {
    height: 340px;
    color: $white;
    background-size: cover !important;
    padding-top: 40px;
    padding-bottom: 40px;
    position: relative;

    a {
        color: $white;
    }

    h1 {
        font-size: $font-size-big;
    }

    .breadcrumb {
        padding: 0;
        margin-bottom: $paragraph-margin-bottom * 1.5;
        font-size: $font-size-tiniest;
        li {      
            &:not(:first-child)::before {
                content: "/";
                margin-left: 10px;
                margin-right: 5px;
            }
    
            a {
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    
    .crumbend {
        display: none;
    }

    @include media-breakpoint-up(md) {
        &.content-page-hero-banner {
            height: 200px;
            padding-top: 20px;
            padding-bottom: 20px;

            .breadcrumb {
                margin-bottom: 20px;
            }
        }
    }

    @include media-breakpoint-down(md) {
        height: 160px;
        background-position: 75% 100% !important;
        padding-top: 0;
        padding-bottom: 0;

        .hero-banner-dark-heading {
            position: absolute;
            width: 100%;
            background-color: $bg-grey;
            height: 100px;
            top: 160px;
            left: 0;
            padding-left: 15px;

            h1 {
                font-size: $font-size-little;
                position: absolute;
                top: 45%;
                -ms-transform: translateY(-45%);
                -webkit-transform: translateY(-45%);
                transform: translateY(-45%);
                width: 100%;
            }
        }

        .crumbcontainer,
        .breadcrumb {
            display: none;
        }

        br {
            display: none;
        }
    }

    @media screen and (max-width: 991px) and (orientation: landscape) {
        .container {
            max-width: 100%;
        }

        .hero-banner-dark-heading {
            text-align: center;
            padding-left: 0;
        }
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
        .container {
            max-width: 100%;
        }

        .hero-banner-dark-heading {
            text-align: center;
            padding-left: 0;
        }
    }
}

@mixin table-mobile {
    .table {
        tr {
            border-bottom: 1px solid $light-grey
        }

        th {
            background-color: transparent;
            font-family: $font-family-helvetica-bold;
            border-top: 0;
            border-bottom: 1px solid $light-grey;
        }

        td {
            border-top: 0;
            padding-top: 40px;
            padding-bottom: 20px;

            &:nth-child(1),
            h2,
            h3 {
                font-family: $font-family-charlie-medium;
                margin-top: -4px;
            }
        }
    }

    @include media-breakpoint-down(md) {
        .table {
            width: 100%;

            th {
                border: 0;
                clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
                clip: rect(1px, 1px, 1px, 1px);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
            }

            td {
                display: block;
                width: 100%;
                padding-top: 30px;
                padding-bottom: 0;

                h2, h3 {
                    margin-top: 0;
                }

                &:nth-child(4) {
                    padding-bottom: 20px;
                }

                &:nth-child(1) {
                    font-size: 20px;
                }

                &:nth-child(2):before,
                &:nth-child(3):before {
                    display: block;
                    width: 100%;
                    font-family: $font-family-helvetica-bold;
                    font-size: 16px;
                    margin-bottom: 15px;
                }

                &:nth-child(2):before {
                    content: "Investment objective \A (net of model manager fees)";
                    white-space: pre-wrap;
                }

                &:nth-child(3):before {
                    content: "Indicative asset class mix";
                }
            }
        }

        &.no-headers {
            .table {
                td {
                    &:nth-child(2):before,
                    &:nth-child(3):before,
                    &:nth-child(4):before {
                        display: none;
                    }
                }
            }
        }

        &.resources-header {
            .table {
                td {
                    &:nth-child(4):before {
                        display: block;
                        width: 100%;
                        font-family: $font-family-helvetica-bold;
                        font-size: 16px;
                        margin-bottom: 15px;
                        content: "Resources";
                    }
                }
            }
        }
    }
}