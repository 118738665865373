[data-toggle="modal"] {
    &, .button-wrapper, .button-text-wrapper {
        display: block !important;
        text-align: center;
    }
}

.modal-dialog {
    max-width: $modal-lg;
    margin: 0 auto;

    @media (min-width: 992px) { 
        padding-top: 60px;
    }

}

.modal-header {
    button.close {
        position: absolute;
        top: 0;
        right: 0;
        padding: $modal-header-padding;
        margin: 0;
    }
}

.modal-content {
    border-radius: 3px;
    display: block;
}

.modal-body {
    padding-bottom: 0;
    p {
        margin-bottom: $paragraph-margin-bottom !important;
    }
}

.modal-subbody {
    background-color: $almost-white;
    margin-left: -$modal-inner-padding;
    margin-right: -$modal-inner-padding;
    margin-bottom: -$modal-inner-padding !important;
    padding: 20px $modal-inner-padding * 0.5;
    border-bottom-left-radius: $modal-content-border-radius;
    border-bottom-right-radius: $modal-content-border-radius;
}

.modal-footer {
    display: none;
}


.modal-profile {
    padding: 0 0 30px;
    .media-left {
        width: 100%;
        text-align: center;
    }

    @media (min-width: 992px) { 
        padding: 0 60px 30px;
    }

    img {
        max-width: 220px;
        align-self: center;

        @media (min-width: 992px) { 
            max-width: 100%;
        }
    }
}

.for-adviser {
    .modal-dialog .close {
        display: none;
    }
}

.modal-heading h2 {
    font-size: 46px
}

.modal-buttons {
    a {
        display: block;
        @media (min-width: 992px) { 
            display: inline-block;
        }
        .primary-button {
            max-width: 280px;
            @media (min-width: 992px) { 
                max-width: auto;
            }
        }
    }
    a:not(:last-child) {
        margin-bottom: 10px;
        @media (min-width: 992px) { 
            margin-right: 50px;
            margin-bottom: 0;
        }
    }
}

.modal-btn-link {
    a {
        padding: 0;
    }
}

//Login modal
.modal-login-header  {
    .media-left {
        display: flex;
        width: 100%;
        justify-content: center;
    }
}

.modal-login-footer-disclaimer {
    font-size: $font-size-tiniest 
}

.modal-footer-disclaimer .text-image{
    margin-bottom: 0
}

.modal-login-footer {
    margin-top: 20px;
    padding-top :20px;
    margin-left: -30px;
    margin-right:-30px;
    padding-left:30px;
    padding-right:30px;
    background-color: #FAF8F4;
    border-radius: 3px;
}

.modal-login-header img {
    margin-bottom: 40px;
    max-width: 300px;
 }

.modal-login-primary h3, 
.modal-login-secondary h3{
    font-size: $font-size-tiny;
}

.modal-login-primary {
    .primary-button{ 
        width: auto;

        a {
            color: $white;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.modal-login-secondary {
    .border-button{ 
        line-height: 38px;
        width: auto;

        a{ 
            color: #c95109;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }   
    }
}

.modal-bio-heading { 
    .text-image {
        margin-bottom: 0;
        h2, h3, h4, h5 {
            margin-bottom: 0;
        }
    }
    p {
        margin-bottom: 0 !important;
        font-size: $font-size-tiniest;
    }
}

.modal-bio-position {
    p {
        font-size: $font-size-tiniest;
    }
}

.modal {
    -webkit-overflow-scrolling: touch
}