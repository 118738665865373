.text-gold *,
.text-gold {
    color: #916c40;
}

.text-light-grey,
.text-light-grey * {
    color: #9b9ea0;
}

.text-585d60,
.text-585d60 * {
    color: #585d60;
}

.text-center,
.text-center * {
    text-align: center;
}

.bg-cbcbcb {
    background-color: #cbcbcb;
}

.bg-585d60 {
    background-color: #585d60;
}

.bg-282828 {
    background-color: #282828;
}

.border-button-gold {
    @extend .primary-button;
    background-color: transparent;
    border: 3px solid #916c40;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    text-decoration: none;
    &:hover, &:focus, &:active {
        background-color: transparent;
        text-decoration: underline;
        border-color: #916c40;
    }
}

.intermede-campaign {
    h2,
    .text-gold *,
    .text-gold {
        color: #916c40;
    }

    .intermede-hr {
        border-top: 1px solid #cbcbcb;
        padding-top: 30px;
    }

    .intermede-banner {
        h1 {
            color: $white;
        }
    }

    .wrapper-register-your-interest {
        @extend .bg-grey;
        height: 100%;
        padding-bottom: 60px;

        p:last-child {
            margin-bottom: 0;
        }
    }

    .call-us,
    .message-us {
        img {
            height: 25px;
            margin-right: 10px;
        }
    }

    .wrapper-disclaimer {
        background-color: #585d60;

        img {
            height: 100px;
        }

        p {
            line-height: 18px;
        }
    }
}

//Fund information page

.wrapper-intermede-banner {
    background-size: cover !important;
    background-position: center center !important;
    margin-bottom: 60px;
}

@media screen and (min-width: 992px) {
    .intermede-campaign {
        .intermede-banner {
            height: 360px;
            background-image: url('/content/dam/mlcam/images/intermede-campaign/intermede-hero-banner.jpg');
            background-repeat: no-repeat;

            h1 {
                margin-top: 120px;
            }
        }

        .wrapper-how-to-invest {
            h3 {
                padding-top: 25px;
            }
        }

        .wrapper-register-your-interest {
            background-position: right !important;
            background-size: 50% !important;
        }
    }

    //Fund information page

    .wrapper-intermede-banner {

        h2 {
            margin-top: 70px;
        }
    }
}

@media screen and (max-width: 991px) {
    .intermede-campaign {
        br {
            display: none;
        }

        .intermede-banner {
            background-image: url('/content/dam/mlcam/images/intermede-campaign/mobile-header-banner.jpg');
            background-repeat: no-repeat;
            height: 360px;

            br {
                display: block;
            }

            h1 {
                margin-top: 160px;
                height: 120px;
            }
        }

        .wrapper-how-to-invest {
            .media,
            .media-left {
                display: block;
                width: 100%;
                text-align: center;
            }

            img {
                margin-bottom: 20px;
            }
        }

        .wrapper-register-your-interest {
            background-image: none !important;
        }

        .wrapper-disclaimer {
            p {
                width: 98%;
                margin-right: 15px;
            }
        }
    }

    //Fund information page

    .wrapper-intermede-banner {
        margin-left: -15px;
        margin-right: -15px;
        height: 150px;
        margin-bottom: 30px;

        h2 {
            margin-top: 65px;
        }
    }
}

@media screen and (max-width: 400px) {
        .border-button-gold {
            font-size: 16px;
        }
    }
