[class*=header-wrapper] {
    position: relative;
    background-position: center !important;
    background-size: cover !important;
    &, a {
        color: $white;
    }
}
.header-wrapper-1, .header-wrapper-2 {
    min-height: 220px;
}
.header-wrapper-1 {
    @extend %gradient-overlay;
    padding-top: 40px;
    padding-bottom: 40px;
}
.header-wrapper-1-light {
    @extend .header-wrapper-1;
    @extend %gradient-overlay-light;
    &, a {
        color: $body-color;
    }
}
.header-wrapper-2 {
    @extend %gradient-overlay;
    padding-top: 20px;
    padding-bottom: 20px;
}
.header-wrapper-3 {
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: $orange;
}
.header-wrapper-3-dark {
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: $black;
}
.intro-section-wrapper {
    background-color: $bg-grey;
    padding-top: 40px;
    padding-bottom: 40px;
    p, .text-image {
        margin: 0;
        padding: 0;
    }
}
.intro-section-wrapper-light {
    background-color: $almost-white;
    padding-top: 40px;
    padding-bottom: 40px;
    p, .text-image {
        margin: 0;
        padding: 0;
    }
}
.intro-section-wrapper-orange {
    background-color: $orange;
    padding-top: 40px;
    padding-bottom: 40px;
    color: $white;
    
    p, .text-image {
        margin: 0;
        padding: 0;
    }
}


.breadcrumb {
    padding: 0;
    margin-bottom: $paragraph-margin-bottom * 1.5;
    font-size: $font-size-tiniest;
    li {      
        &:not(:first-child)::before {
            content: "/";
            margin-left: 10px;
            margin-right: 5px;
        }

        a {
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}
.crumbend {
    display: none;
}

.hero-banner-dark {
    @include hero-banner;
}

@include media-breakpoint-down(md) {
    .header-wrapper-1, .header-wrapper-2, .intro-section-wrapper, .intro-section-wrapper-light {
        padding-top: 20px;
        padding-bottom: 20px;
        height: auto;
    }
    .header-wrapper-1, .header-wrapper-2 {
        min-height: 160px;
    }

    .header-wrapper-1,
    .header-wrapper-1-light,
    .header-wrapper-2 {
        &::before {
            background-image: linear-gradient(90deg, rgba($black, 0.8) 100%, transparent 70%);
        }
    }

    .header-wrapper-3, .header-wrapper-3-dark  {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .breadcrumb {
        margin-bottom: $paragraph-margin-bottom * 0.5;
    }
}

@include media-breakpoint-down(sm) {
    .header-wrapper-1,
    .header-wrapper-1-light {
        padding-bottom: 0;
        
        .container {
            padding: 30px 15px 10px;

            .font-size-huge {
                font-size: 40px;

                br {
                    display: none;
                }
            }
        }
    }
}
