.footer-wrapper{
  background-color: $bg-grey;
  color: $very-light-grey;
  padding: 20px 0;
  .bootstrap-row, .nav {
      margin: 10px 0;
      a {
        color: $very-light-grey;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
  }
  p {
    margin-bottom: 0;
  }
  .footer-logo {
    > div {
      margin-top: 10px;
      margin-bottom: 20px;
      @include media-breakpoint-down(md) {
        margin-bottom: 10px;
      }
    }
    .social-logo {
      color: $white;
      font-family: $font-family-charlie-semibold;
      font-size: $font-size-social-media;
      padding: 15px 0;
      @include media-breakpoint-down(md) {
        margin-top: 20px;
      }
      .text-image {
        text-align: right;
        width: 100%;
        @include media-breakpoint-down(md) {
          text-align: left;
        }
      }
      img {
        margin-bottom: 5px;
      }
    }
    .text-image {
      margin-bottom: 0px;
    }
  }

  .footer-links {
		a {
			text-decoration: underline;

			&:hover,
			&:focus,
			&:visited {
				text-decoration: none;
			}
		}
  }
  
  .linkedin-logo {
    img {
      margin-bottom: 5px;
    }
  }
}