$card-spacing: 20px;
$card-spacing-sm: 10px;
$card-spacing-lg: 30px;

.card-wrapper-md {
    .card-description {
        > * {
            padding-left: $card-spacing;
            padding-right: $card-spacing;
        }
        > *:first-child {
            padding-top: $card-spacing;
        }
        > *:last-child {
            padding-bottom: $card-spacing;
        }
    }
}
.card-wrapper-lg {
    .media-left {
        width: 100%;
    }
    .image-card-nospace {
        @include media-breakpoint-up(lg) {
            padding-right: 15px;
        }
    }

    .image-card--feature {
        @include media-breakpoint-up(lg) {
            margin-left: -15px;
        }
    }

    .card-description {
        > * {
            padding-left: $card-spacing-lg;
            padding-right: $card-spacing-lg;
        }
        > *:first-child {
            padding-top: $card-spacing-lg;
        }
        > *:last-child {
            padding-bottom: $card-spacing-lg;
        }
    }
}
.card-wrapper-feature {
    @extend .card-wrapper-lg;
    color: $white;
    .media {
        .media-left {
            width: 100%
        }
    }
    .card-description > * {
        background-color: $bg-grey;
    }
}
.card-wrapper-top {
    .card-image {
        margin-bottom: 0;
        .media {
            position: relative;
        }
        img {
            position: relative;
            max-width: 100%;
            height: auto;
        }
    }
    .card-description {
        .media-left {
            margin: auto;
            margin-bottom: $card-spacing;
            width: 100%;
            text-align: center;
        }
    }
}
.card-wrapper-slim {
    .card-description {
        > * {
            background-color: transparent;
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }
    }
    > .card-align {
        .text-image, p {
            margin-bottom: 10px;
        }
    }
}
.card-wrapper-manager {
    .card-description {
        > * {
            background-color: $white;
        }
    }
}
.card-wrapper-product {
    .card-description>* {
        padding-right: 25px;
    }
    .card-description {
        > * {
            background-color: transparent;
            border: 1px solid $medium-grey;
            width: 100%;
        }
        &, a {
            display: flex;
            align-items: center;
            min-height: 50px;
            color: $orange;
        }
        .media {
            position: relative;
            &::after {
                content: "\f054";
                font-family: $font-family-font-awesome;
                position: absolute;
                top: 50%;
                right: -10px;
                transform: translateY(-50%);
            }
        }
        .text-image {
            flex: 1;
        }
    }
}

.card-wrapper-how-to-invest {
    .card-description {
        margin-bottom: 30px;

        .text-image {
            margin-bottom: 0;
        }
    }
}

.card-image {
    margin-bottom: $paragraph-margin-bottom;
    .media {
        display: flex;
        position: absolute;
        justify-content: center;
        height: 100%;
        width: 100%;
        overflow: hidden;
    }
    img {
        position: absolute;
        height: 100%;
        max-width: none;
        top: 0;
        left: 50%;
        transform: translate(-50%,0);
    }
}

.card-image--mobile img{
    @include media-breakpoint-down(md) {
        width: 100vw;
        margin-bottom: 20px;
    }
}

.card-description {
    margin-bottom: $paragraph-margin-bottom;
    > * {
        background-color: $light-grey;
        padding-left: $card-spacing;
        padding-right: $card-spacing;
    }
    > *:first-child {
        padding-top: $card-spacing;
    }
    > *:last-child {
        padding-bottom: $card-spacing;
    }
    >div:last-child *:last-child {
        margin-bottom: 0;
    }
    .media-left {
        margin-right: $card-spacing;
        &+.text-image {
            flex: 1;
        }
    }

    .fa-exclamation-triangle {
        margin-left: -10px;
        color: $orange;
    }

    .card-associated-risks-heading {
        padding: 10px 10px 0 10px;
    }

    .card-associated-risks-description {
        padding: 0 10px 10px 10px;
    }
}

.card-wrapper-benefits-of-investing {
    .card-description {
        .media-left {
            margin: auto auto 15px auto;
        }

        .text-image {
            margin-bottom: 10px;

            p {
                margin-bottom: 10px;
            }
        }
    }
}

.card-align {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .multiple-columns {
        height: 100%
    }

    .media-left,
    .pull-left {
        width: 100%;
    }
}
.card-align-light-grey-bg, .card-align-grey-bg, .card-align-white-bg {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 0;
    position: relative;

    .multiple-columns {
        height: 100%
    }

    &::before {
        bottom: 0;
        content: "";
        left: 15px;
        position: absolute;
        right: 15px;
        top: 0;
        z-index: -1;
    }
    > .card-description>:last-child {
        padding-top: 0;
    }
    > .bootstrap-text-image:last-child > .card-description {
        margin-bottom: 0;
        .media-body {
            padding-top: 0;
        }
    }
}
.card-align-light-grey-bg::before {
    background-color: $light-grey !important;
}
.card-align-grey-bg::before {
    background-color: $bg-grey !important;
}
.card-align-white-bg {
    margin-bottom: 30px;
    &::before {
        background-color: $white !important;
    }
}
.people-card {
    .card-align, .card-align-light-grey-bg {
        .text-image {
            width: 100%;
            margin-bottom: 0;
            h2, h3, h4, h5 {
                margin-top: 10px;
            }
            p {
                margin-bottom: 5px;
            }
        }
    }
}
.news-media-card {
    .card-align {
        .text-image {
            margin-bottom: 0;
            h2, h3, h4, h5 {
                margin-top: 20px;
                margin-bottom: 0px;
            }
        }
    }
}
.image-card-nospace {
    p, .text-image {
        margin-bottom: 0px;
    }
}
.card-group-wrapper {
    margin-bottom: 60px;

    @include media-breakpoint-down(md) {
        margin-bottom: 0;
    }
}

.card-button-bottom {
    @include media-breakpoint-up(lg) {
        position: absolute;
        bottom: 0;
        left: 10px;
    }
}

.card-content-description {
    .text-image {
        padding-top: 30px;
        padding-left: 30px;
        padding-right: 30px;
        margin-bottom: 10px;
    }

    h2, h3, h4 {
        margin-bottom: 15px;
    }

    .fa-fw {
        width: auto;
        margin-right: 10px;
    }
}

@include media-breakpoint-down(md) {
    .card-wrapper-md {
        .card-description {
            > * {
                padding-left: $card-spacing-sm;
                padding-right: $card-spacing-sm;
            }
            > *:first-child {
                padding-top: $card-spacing;
            }
            > *:last-child {
                padding-bottom: $card-spacing;
            }
        }
    }
    .card-wrapper-lg {
        .media-left {
            width: 100%;
        }
        .card-description {
            > * {
                padding-left: $card-spacing-sm;
                padding-right: $card-spacing-sm;
            }
            > *:first-child {
                padding-top: $card-spacing;
            }
            > *:last-child {
                padding-bottom: $card-spacing;
            }
        }
    }

    .card-wrapper-manager, .card-wrapper-product {
        .card-description {
            margin-bottom: $paragraph-margin-bottom * 0.5;
        }
    }

    .card-wrapper-how-to-invest {
        .card-description {
            margin-bottom: 20px;
        }
    }

    .card-image {
        margin-bottom: 0;
        .media {
            position: relative;
        }
        img {
            position: relative;
            max-width: 100%;
            height: auto;
        }
    }
    .card-description {
        > * {
            padding-left: $card-spacing-sm;
            padding-right: $card-spacing-sm;
        }
        .media {
            flex-direction: column;
            div {
                width: 100%;
                &:first-child {
                    flex: 1 1 auto;
                }
            }
        }
        .media-left {
            display: block;
            text-align: center;
        }
    }
    .card-align-light-grey-bg, .card-align-grey-bg {
        margin-bottom: 15px;
    }
    .card-align-white-bg {
        margin-bottom: 10px;
    }
    .card-stepper-icon {
        .block-space-mb > div:nth-child(odd) {
            margin-bottom: 30px;
        }
        .text-image {
            text-align: center;
        }
    }
    .news-media-card {
        .card-align > .bootstrap-text-image .text-image:last-child {
            margin-bottom: 20px;
        }
    }

    .card-content-description {
        margin-bottom: 30px;

        .text-image {
            padding-top: 15px;
            padding-left: 15px;
            padding-right: 15px;
            padding-bottom: 10px;
        }
    }
}

@media screen and (min-width: 992px) {
    .wrapper-client-education .card-content-description {
        height: 380px;
        position: relative;

        .border-button-white {
            position: absolute;
            bottom: 0;
            margin-bottom: 30px;
        }
    }
}